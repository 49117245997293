exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-mdx": () => import("./../../../src/pages/kontakt.mdx" /* webpackChunkName: "component---src-pages-kontakt-mdx" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-moje-prace-mdx": () => import("./../../../src/pages/moje-prace.mdx" /* webpackChunkName: "component---src-pages-moje-prace-mdx" */),
  "component---src-pages-nazory-mdx": () => import("./../../../src/pages/nazory.mdx" /* webpackChunkName: "component---src-pages-nazory-mdx" */),
  "component---src-pages-novinky-js": () => import("./../../../src/pages/novinky.js" /* webpackChunkName: "component---src-pages-novinky-js" */),
  "component---src-pages-odkazy-js": () => import("./../../../src/pages/odkazy.js" /* webpackChunkName: "component---src-pages-odkazy-js" */),
  "component---src-pages-pribeh-mdx": () => import("./../../../src/pages/pribeh.mdx" /* webpackChunkName: "component---src-pages-pribeh-mdx" */),
  "component---src-pages-senatorske-dny-mdx": () => import("./../../../src/pages/senatorske-dny.mdx" /* webpackChunkName: "component---src-pages-senatorske-dny-mdx" */)
}

